$font-family-text: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
sans-serif;
$font-family-display: 'Lekton', sans-serif;
$font-family-special: 'Special Elite', cursive;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 600;

$font-size-x-small: 0.625rem;
$font-size-small: 0.75rem;
$font-size-regular: 0.875rem;
$font-size-large: 1rem;
$font-size-x-large: 1.25rem;
$font-size-xx-large: 1.5rem;
$font-size-xxx-large: 2rem;
$font-size-xxxx-large: 2.125rem;

$letter-spacing-normal: 0.1px;
$letter-spacing-enhanced: 0.5px;

@mixin button() {
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: $letter-spacing-normal;
  text-decoration: none;
  text-transform: uppercase;
  font-family: $font-family-text;
}

@mixin display() {
  font-family: $font-family-display;
  font-size: $font-size-regular;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-enhanced;
}

@mixin error() {
  font-family: $font-family-text;
  color: $red;
  line-height: $font-size-x-large;
  font-style: italic;
}

@mixin cursive($size: $font-size-regular) {
  font-family: $font-family-text;
  color: $grey-600;
  font-style: italic;
  font-size: $size;
}

@mixin link($color: $black, $bgColor: $yellow, $activeColor: $black) {
  color: $color;
  background:
     linear-gradient(
       to bottom, $bgColor 0%,
       $bgColor 100%
     );
  background-position: 0 100%;
  background-repeat: repeat-x;
  background-size: 2px 2px;
  text-decoration: none;
  transition: background-size .2s, color .2s;
  text-decoration: none;
  font-family: $font-family-display;
  font-size: $font-size-regular;
  letter-spacing: 0.075em;

  &:hover {
    background-size: 2px 50px;
    color: $activeColor;
  }
  #{if(&, '&.active', '.link--active')} {
    background-size: 2px 50px;
    color: $activeColor;
  }
}