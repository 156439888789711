$box-shadow: 0 2px 2px 0 rgba(0,0,0,.16), 0 0 2px 0 rgba(0,0,0,.12);
$phone: "(max-width: 839px)";

@mixin button-reset {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  box-shadow: 0;
  outline: 0;
}

@mixin lightInput() {
  width: 100%;
  position: relative;
  margin-bottom: $spacing-regular;

  input,
  textarea {
    font-family: monospace;
    width: 100%;
    border: 0;
    padding: 4px 0;
    border-bottom: 1px solid #9E9E9E;
    background-color: transparent;
    resize: vertical;
  }

  span {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  span::before {
    content: "";
    display: block;
    position: absolute;
    bottom: 0px;
    left: 0;
    width: 0;
    height: 1px;
  
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
  
    background: #000;
  }
  
  input:focus + span::before,
  textarea:focus + span::before {
    width: 100%;
    box-shadow: 2px 2px 0 1px #eec71a;
  }
  
  input.has-value + span::before,
  textarea.has-value + span::before {
    width: 100%;
  }
}

@mixin shadow($color: #eec71a) {
  box-shadow: 4px 4px 0 0 $color;
}

@mixin solidBoxShadow($color: #eec71a) {
  border: 3px solid #000;
  box-shadow: 4px 4px 0 0 $color;
}

@mixin box($color: #000000) {
  border: 3px solid $color;
  background-color: white;
}

@mixin ripple($color: #FAFAFA, $active-color: #EEEEEE) {
  background-position: center;
  transition: background 0.5s;
  cursor: pointer;
  &:hover {
    background: $color radial-gradient(circle, transparent 1%, $color 1%) center/15000%;
  }

  &:active { 
    background-color: $active-color;
    background-size: 100%;
    transition: background 0s;
  }
}

@mixin elevated() {
  position: relative;
  
  &:hover,
  &:focus {
    @include solidBoxShadow();
    top: -4px;
    left: -4px;
  }
  
  &:active,
  &:active + &:hover {
    border: 3px solid $black;
    box-shadow: none!important;
    top: 0px;
    left: 0px;
  }
}