@import './_variables.scss';

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(../fonts/Roboto/Roboto-Light.ttf) format('ttf');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url(../fonts/Roboto/Roboto-Light.ttf) format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(../fonts//Roboto/Roboto-Regular.ttf) format('ttf');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url(../fonts//Roboto/Roboto-Regular.ttf) format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(../fonts/Roboto/Roboto-Medium.ttf) format('ttf');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'), url(../fonts/Roboto/Roboto-Medium.ttf) format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* latin-ext */
@font-face {
  font-family: 'Lekton';
  font-style: normal;
  font-weight: 400;
  src: local('Lekton'), local('Lekton-Regular'), url(../fonts/Lekton/Lekton-Regular.ttf) format('ttf');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lekton';
  font-style: normal;
  font-weight: 400;
  src: local('Lekton'), local('Lekton-Regular'), url(../fonts/Lekton/Lekton-Regular.ttf) format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Lekton';
  font-style: normal;
  font-weight: 700;
  src: local('Lekton-Bold'), url(../fonts/Lekton/Lekton-Bold.ttf) format('ttf');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Lekton';
  font-style: normal;
  font-weight: 700;
  src: local('Lekton-Bold'), url(../fonts/Lekton/Lekton-Bold.ttf) format('ttf');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
}

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

:root {
  --mdc-theme-primary: #eec71a;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family-text;
  font-size: $font-size-regular;
  color: $grey-900;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $grey-100;
}

::selection { 
  background: $yellow;
  color: black;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

h1 {
  font-family: $font-family-display;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  font-size: $font-size-xx-large;
}

h6 {
  font-family: $font-family-display;
  font-weight: $font-weight-bold;
  font-size: $font-size-x-large;
}

h4 {
  margin: 0;
  font-family: $font-family-display;
  font-weight: $font-weight-bold;
  font-size: $font-size-xx-large;
  line-height: 1.5rem;
}

h4:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 50px;
  margin-top: -14px;
  border-bottom: 16px solid;
  border-bottom-color: $grey-300;
  margin-left: -4px;
}

h5 {
  margin: 0;
  font-family: $font-family-display;
  font-size: $font-size-large;
  font-weight: $font-weight-bold;
  letter-spacing: $letter-spacing-enhanced;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.cbk-app-drawer {
  flex-basis: 16rem;
  flex-grow: 1;
}

.cbk-main {
  flex-basis: 0;
  flex-grow: 9999;

  .mdc-layout-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    .mdc-layout-grid__inner {
      width: 100%;
    }
  }

}

.cbk-app-content {
  padding-bottom: $spacing-regular;
}

.cbk-burger {
  position: fixed;
  top: 0;
  z-index: 9999;
}

input,
label,
textarea
{
	margin:0;
	border:0;
	padding:0;
	display:inline-block;
	vertical-align:middle;
	white-space:normal;
	background:none;
	line-height:1;
}

/* Remove the stupid outer glow in Webkit */
input:focus,
textarea:focus
{
	outline:0;
}

/* Box Sizing Reset
-----------------------------------------------*/

/* All of our custom controls should be what we expect them to be */
input,
textarea
{
	-webkit-box-sizing:content-box;
	-moz-box-sizing:content-box;
	box-sizing:content-box;
}

select {
  @include box();
  border-width: 2px;
}

select:focus {
  @include shadow();
  outline: none;
}

/* 
  ##Device = Low Resolution Tablets, Mobiles 
*/

@media (max-width: 839px) {
  
  #root {
    flex-direction: column;
    display: flex;
    flex-wrap: unset;

    .cbk-app-drawer {
      flex-basis: unset;

      .cbk-drawer {
        width: 100%;
        flex-direction: row;
        padding: 1rem;
        h1.mdc-drawer__title {
          display: none;
        }
        .mdc-drawer__header,
        .mdc-list {
          min-height: unset;
          padding: 0;
          display: flex;
          flex-direction: row;
          font-size: $font-size-small;
          .mdc-list-item {
            margin: 0;
            a {
              font-size: $font-size-regular;
            }
          }
        }

        .cbk-icon svg {
          width: 35px!important;
        }
      }
    }
  }
  
}