$primary-color: #9E9E9E;

$white: #FFFFFF;
$black: #000000;

$grey-100: #FAFAFA;
$grey-200: #EEEEEE;
$grey-300: #E0E0E0;
$grey-400: #BDBDBD;
$grey-500: #9E9E9E;
$grey-600: #757575;
$grey-700: #616161;
$grey-800: #424242;
$grey-900: #212121;

$pink: #e89d93;

$red: #F25F5C;

$yellow: #eec71a;
$yellow-dark: #d0ae16;

$orange: #FF9311;

$blue: #48ACF0;