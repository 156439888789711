@import 'styles/_variables.scss';

.cbk-planner {
  &__actions {
    display: flex;
    padding: 0 $spacing-small;
    padding-top: $spacing-small;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
  }
  &__shopping {
    &__actions {
      @extend .cbk-planner__actions;
    }
    &__list {
      padding: $spacing-small;
      .cbk-shopping-list {
        @include box();
      }
    }
  }
  &__body {
    padding: $spacing-small;
    &__planner {
      display: flex;
      @include box();
      .week {
        display: flex;
        flex-basis: 100%;
        .day,
        .meal {
          flex: 1;
          display: flex;
          flex-direction: column;
          background-color: black;
          border-bottom: 1px solid black;
          min-width: 0;
          &-date,
          .meal-placeholder {
            flex-basis: 1rem;
            color: white;
            font-family: $font-family-display;
            h5 {
              padding: 0 $spacing-small/2;
            }
          }
          &-meal {
            display: flex;
            flex-direction: column;
            justify-content: center;
            flex-basis: 5rem;
            background-color: white;
            box-sizing: border-box;
            margin: 0.5px;
            padding: 0 $spacing-small/2;
            h5 {
              text-align: center;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              cursor: pointer;
            }
            &--edit {
              justify-content: end;
              button {
                align-self: flex-end;
              }
            }
            .cbk-recipe-search {
              width: auto;
            }
          }
        }
        .meal {
          max-width: $spacing-regular;
          flex-basis: $spacing-regular;
          &-placeholder {
            color: black!important;
          }
          &-name {
            flex-basis: 5rem;
            background-color: white;
            box-sizing: border-box;
            margin: 0.5px;
            padding: 0!important;
            &--style {
              writing-mode: vertical-lr;
              margin-top: $spacing-small/2;
            }
          }
        }
        @media (max-width: 840px) {
          flex-direction: column;
        }
      }
    }
  }

  &-dnd__body {
    display: flex;
    margin: 1rem 0.5rem;

    &__backlog {
      margin: 0 0.5rem;
      flex-basis: 20%;
    }

    &__calendar {
      margin: 0 0.5rem;
      flex: 1;
      .container {
        display: flex;
        justify-content: space-between;
        background-color: white;
        .day-meals {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;

          > div {
            height: 5rem;
            
          }
        }

        .day-schedule {
          flex-basis: 14%;
          max-width: 14%;
          background-color:white;
          text-align: center;
          display: flex;
          flex-direction: column;

          &--meals {
            max-width: none;
            flex-basis: 2%;
            h5 {
              writing-mode: vertical-lr;
              text-orientation: mixed;
              margin: 0.5rem 0;
            }
          }

          &--date {
            height: 3rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 2rem;
          }

          &--meal {
            height: 5rem;
            justify-content: center;
            align-items: center;
            border: 1px solid gainsboro;
          }

          &-content {
            width: inherit;
            height: 100%;
            display: flex;

            .meal-card {
              display: flex;
              flex-direction: column;
              width: 100%;
              align-items: center;

              &--actions {
                flex-basis: 30%;
                display: flex;
                width: 100%;
                justify-content: flex-end;
              }
            }
          }
        }
      }
    }
  }

}

.cbk-planner-mobile {
  &__body {
    &__calendar {
      padding: $spacing-small/2;
      .day-schedule {
        display: flex;
        margin-bottom: $spacing-small/2;
        &__day {
          writing-mode: vertical-rl;
          height: 5rem;
          background-color: $grey-900;
          color: $white;
          padding: 0.3rem;
        }
        &__meals {
          min-width: 0;
          width: 100%;
          border-top: 1px solid $grey-300;
          border-bottom: 1px solid $grey-300;
          border-right: 1px solid $grey-300;
          &--0,
          &--1 {
            border-bottom: 1px solid $grey-300;
          }
          &--0,
          &--1,
          &--2 {
            padding: 2px 4px;
            height: 1.5rem;
            h5 {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }
}